import React, { useState } from "react";
import axios from "axios";

import "./App.css";

function App() {
  const [url, setUrl] = useState("");
  const [cred, setCred] = useState("");
  const [summary, setSummary] = useState("");
  const [briefing, setBriefing] = useState("");
  const [overview, setOverview] = useState("");
  const [recommendation, setRecommendation] = useState("");
  const [findings, setFindings] = useState("");
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Make API call to cloud function
    axios
      .post("https://aamly-teigvmq2aa-uc.a.run.app", {
        url: url,
        cred: cred
      }, {
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 3600000 // Set timeout to 60 minutes (3600000 milliseconds)
      })
      .then((response) => {
        setSummary(response.data.summary);
        setBriefing(response.data.briefing);
        setOverview(response.data.overview);
        setRecommendation(response.data.recommendation);
        setTitle(response.data.title);
        setFindings(response.data.findings);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <div className="container">
      <h1 className="title">ByteBrief: A Briefing Tool</h1>
      {/* <h3 className="title">Input: Article, Youtube vdo or Arxiv pdf or any online pdfs</h3> */}
      <form className="form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-label">
            URL:
            <input
              className="form-input"
              type="text"
              value={url}
              onChange={(event) => setUrl(event.target.value)}
              placeholder="Articles, Youtube videos, Research Papers from Arxiv or Online PDFs"
            />
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">
            Credentials:
            <input
              className="form-input"
              type="text"
              value={cred}
              onChange={(event) => setCred(event.target.value)}
              placeholder="OpenAI API credentials"
            />
            <span className="form-help-text">
            Here is a step-by-step guide to get OpenAI API credentials:
            <ol>
                <li>Go to the OpenAI website: <a href="https://beta.openai.com" target="_blank" rel="noopener noreferrer">https://beta.openai.com</a></li>
                <li>Click on "Sign up" to create a new account. Fill out the required information and complete the sign-up process.</li>
                <li>Log in to your OpenAI account.</li>
                <li>Navigate to the "API" section of the OpenAI Beta dashboard.</li>
                <li>Click on "Create New API Key" to generate a new API key.</li>
                <li>Give your API key a descriptive name and set any necessary permissions.</li>
                <li>Click "Create Key" to generate your API key.</li>
                <li>Your API key will be displayed on the screen. Copy and store it securely, as it will not be displayed again.</li>
                <li>You can now use your API key to access OpenAI API services.</li>
            </ol>
            
            </span>
          </label>
        </div>
        <button className="form-button" type="submit" disabled={isLoading}>
          {isLoading ? "Loading..." : "Submit"}
        </button>
      </form>
      {isLoading && <div className="loading">Loading...</div>}
      {!isLoading && summary && (
        <div className="output">
          <h2 className="output-title"></h2>
          <textarea className="output-text" rows={2} value={title} readOnly />
          {/* <h2 className="output-title">Overview:</h2>
          <textarea className="output-text" rows={10} value={overview} readOnly /> */}
          <h2 className="output-title">Executive Briefing:</h2>
          <textarea className="output-text" rows={15} value={briefing} readOnly />
          <h2 className="output-title">Key Findings:</h2>
          <textarea className="output-text" rows={15} value={findings} readOnly />
          <h2 className="output-title">Recommendation:</h2>
          <textarea className="output-text" rows={15} value={recommendation} readOnly />
          <h2 className="output-title">Topics Discussed:</h2>
          <textarea className="output-text" rows={20} value={summary} readOnly />
        </div>
      )}
    
    <footer className="footer">
      <p>Contact us at bytebriefai@gmail.com</p>
      </footer>
    </div>
  );
}

export default App;
